<template>
    <h1 class="mb-5">Sjekkliste ved heisstopp</h1>
    <p>
        Har vareheisen stoppet og vil ikke starte igjen?
    </p>
    <p class="mb-5">
        Før dere får helt hetta og vil knuse hele vareheisen, så titt igjennom denne sjekklista som løser 90% av alle vareheisstopp vi får inn. 
        Om ingenting av dette løser problemet kan dere knuse vareheisen!.... ok nei ikke gjør det! Registrer da en hendelse hos <a href="https://rajelektro.no/temco_schema/#/" target="_blank">RAJ Elektro</a>, så kontakter de dere så fort de kan.
    </p>
    
    <h3 class="mb-3">Er alle nødstopp-knapper resatt?</h3>
    <p>
        Denne er ganske vanlig! Ofte så er det en nødstopp i en av etasjene som har blitt trykket på av en grunn og da vil ikke vareheisen gå. 
        Sjekk at ingen av nødstopp-knappene er trykket på i noen av etasjene. Du må dra knappen ut slik at den "knepper" ut. 
        Om du er usikker så kan du teste ved å trykke den inn. Om den ikke "klikker" og du merker at den "går inn" så er den allerede trykket på. 
        Da kan du dra den ut til den "knepper". Om det ikke hjelper å dra den ut, dvs den "knepper" ikke, kan det hende dere har en annen type nødbryter. 
        Prøv da å "vri" den litt til venstre og da vil den "poppe" ut igjen.
    </p>

    <h3 class="mt-5 mb-3">Er alle dører lukket?</h3>
    <p>
        Det er kanskje innlysende at dørene må være lukket for at vareheisen skal virke, og for alt du kan se, så er alle dørene igjen. 
        Tingen er det at selv om døra er lukket, så er det 2 ting som skal være på plass for at døra "virkelig" er lukket.
    </p>

    <div class="ml-5">
        <h4 class="mt-5 mb-3">1. Er døra låst?</h4>
        <p>
            Når døra er igjen så går det en lås-bolt fra lista over døra og inn i et hull i toppen døra for å holde den låst mens heisen går. Det kan hende at lås-bolten ikke helt har klart å treffe hullet. 
            Dytt litt på døra og sørg for at døra blir låst slik at du ikke klarer å lukke den opp igjen. 
            
            <span class="font-weight-bold">! Ikke hal og dra hardt i døra! Om døra ikke åpner seg ved normal trekk så er det ok.</span>
        </p>
        <img class="d-inline-block mr-4 shadow rounded img-fluid mb-3" src="@/assets/images/Dr-15.png" alt="Figur 1" style="max-height: 290px">
        <img class="d-inline-block shadow rounded img-fluid mb-3" src="@/assets/images/Dr-25.png" alt="Figur 2" style="max-height: 290px">


        <h4 class="mt-5 mb-3">2. Sitter dørbryteren godt på plass?</h4>
        <p>
            Det stikker opp en metall-brakett fra døra med en spiss tupp (dørbryterkontakt) stikkende ut og mot vareheisen. Det er en dørbryter som forteller vareheisen at døra er lukket. 
            Om den spissen ikke treffer inn i sporet sitt eller ikke kommer langt nok inn, vil vareheisen ikke gå. Sørg for at den er godt plassert i sporet sitt ved å dytte litt på døra.
        </p>
        <img class="d-inline-block mr-4 shadow rounded img-fluid" src="@/assets/images/Dr-17.png" alt="Figur 3" style="max-height: 290px">
    </div>

    <h3 class="mt-5 mb-3">Lyser det noen lamper på styreskapet?</h3>
    <p>
        Ta en titt på styreskapet som står i en av etasjene (ofte nederst) i nærheten av vareheisen (ofte plassert på selve heisen) for å se om det lyser noen røde lamper eller om noen av de grønne ikke lyser.
    </p>
    <img class="d-inline-block mr-4 shadow rounded mb-4 img-fluid" src="@/assets/images/styreskap.jpg" alt="Figur 4" style="max-height: 290px">
    <p>
        Når heisen går som vanlig så skal det lyse 3 grønne lamper og ingen røde lamper. Om ikke alle de 3 grønne lampene lyser eller det er noen røde lamper som lyser må du registrere en hendelse hos <a href="https://rajelektro.no/temco_schema/#/" target="_blank">RAJ Elektro</a>, så kontakter de dere så fort de kan.
    </p>
</template>
<script>
export default {
    name: "SupportChecklist"
}
</script>>